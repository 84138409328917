<template>
  <div id="app">
    <router-view />
    <!-- <Loading v-if="vloading"></Loading> -->
    <!-- <Dialog v-model="vshow" @change="onClose"></Dialog> -->
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
// import Dialog from "@/components/dialog.vue";

export default {
  data() {
    return {
      vshow: true,
    };
  },
  components: {
    // Loading,
    // Dialog,
  },
  created() {
    this.$nextTick(() => {
    });
  },
  methods: {
    onClose(e) {
      console.log(e, this.vshow);
    },
    onClick() {},
    setData() {
      // this.$store.dispatch("action_updateApp", {
      //   type: "discoverList",
      //   query: {},
      // });
    },
    handleCustomEvent() {
     
    },
  },
  computed: {
    vloading() {
      return this.$store.getters.GET_LOGING;
    },
    trMoney() {
      return this.money.toLocaleString();
    },
    // config(){
    //   return this.$store.getters.GET_CONFIGLIST
    // }
  },
  // mounted(){
  //   if(this.config && this.config[0]?.sceneId == '30001'){
  //     if(!this.config?.taskExtraInfo){
  //       this.vshow = false;
  //     }
  //   }
  // }
};
</script>

<style lang="scss">
.adsbygoogle {
  margin-bottom: 4vw;
}

.adsbygoogle[data-ad-status='unfilled'] { 
  display: none !important;
}
._margin{
  padding-left: 5%;
  padding-right: 5%;
}
</style>
