import Vue from "vue";
import Vuex from "vuex";
import * as api from "@/api/common/index.js";
import {defaultOfferWall} from '@/config/index';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    search: '',
    user: undefined,
    loading: false,
    appData: {
      offer: null,
    },
    configList: null,
    // eslint-disable-next-line no-undef
    cachOfferIdList: offerWall.cachOfferIdList,  //上报offer_show的offerId列表
    // eslint-disable-next-line no-undef
    accountInfo: offerWall.accountInfo,  //账号信息
    subList: null,  //子任务列表
  },
  getters: {
    GET_SEARCH (state) {
      return state.search
    },
    GET_USER(state) {
      return state.user;
    },
    GET_LOGING(state) {
      return state.loading;
    },
    GET_APPDATA(state) {
      return state.appData;
    },
    GET_CONFIGLIST(state) {
      return state.configList;
    },
    GET_CACHOFFERIDLIST(state) {
      return state.cachOfferIdList;
    },
    GET_ACCOUNTINFO(state) {
      return state.accountInfo;
    },
    GET_SUBLIST(state) {
      return state.subList;
    },
  },
  mutations: {
    change_loading(state, data) {
      state.loading = data;
    },
    update_login(state, data) {
      state.user = data;
    },
    /**
     * @description:
     * @param {*} state
     * @param {*} data
     * @return {*}
     */
    update_offerList(state, { data }) {
      state.appData.offer = data;
    },
    update_configList(state, { data }) {
      state.configList = data;
    },
    update_cachOfferIdList(state, { data }) {
      state.cachOfferIdList = data;
    },
    update_accountInfo(state, { data }) {
      state.accountInfo = data;
    },
    update_subList(state, { data }) {
      state.subList = data;
    },
    change_search (state, data) {
      state.search = data;
    },
  },
  actions: {
    action_search ({ commit }, data) {
      commit("change_search", data);
    },
    action_loading({ commit }, data) {
      commit("change_loading", data);
    },
    action_login({ commit }) {
      return new Promise((resolve) => {
        commit("change_loading", true);
        api.userLoginApi({
          svName:'/lgin', 
          mdName:'post', 
          // eslint-disable-next-line no-undef
          gi: offerWall.gi || defaultOfferWall.gi, 
          qd: '',
          ct: 'US', 
          su: 'GMT+08:00'
        }).then((res) => {
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_login", res.data);
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },

    /**
     * @description: 更新offer数据
     * @param {*} store
     * @param {*} query
     * @return {*} void
     */
    async action_get_offerList({ commit, state }, { query, queryChild, init }) {
      commit("change_loading", true);
      const res = await api.offerListApi(query);
      const cacheList = state.appData.offer?.offerList || []
      if (state.appData.offer) {
        if (init) {
            commit('update_offerList', {data: undefined})
        }
      }
      if (queryChild) {
        const resChild = await api.offerChildListApi(queryChild)
        if (res.code == 0 && resChild.code == 0 ) {
            // TODO 对子任务进行合并
            console.log(resChild, '合并offer 及offer 子任务')
            // res.data.offerList = [...cacheList,  res.data.offerLis];
            // commit("update_offerList", { data: res.data });
        }
      } else {
        if (res.code == 0 && res.data) {
          res.data.offerList = [...cacheList,  ...res.data.offerList];
          commit("update_offerList", { data: res.data });
        }
      }
      commit("change_loading", false);
    },
    action_get_configList({commit}){
      return new Promise((resolve) => {
        commit("change_loading", true);
        api.gameConfigApi({
          svName:'/hcfg', 
          mdName:'post', 
          // eslint-disable-next-line no-undef
          svsn: offerWall.productVersion || defaultOfferWall.productVersion, 
          // eslint-disable-next-line no-undef
          qdo: offerWall.channel || defaultOfferWall.channel, 
          bzc: ''
        }).then((res) => {
          // console.log('游戏配置',res);
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_configList", {data: res.data.map(item => {
              item.taskExtraInfo = item.taskExtraInfo ?  JSON.parse(item.taskExtraInfo) : {}
              return item 
            })});
            // localStorage.setItem('configList', JSON.stringify(res.data));
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },
    action_get_cachOfferIdList({commit}, {data}){
      commit('update_cachOfferIdList', { data });
      try{
        window.OfferWall?.putString('cachOfferIdList', JSON.stringify(data));
      }catch(e){
        console.log('android not set', e);
      }
    },
    action_accountInfo({ commit }, data) {
      console.log('accountInfo', data);
      commit("update_accountInfo", {data});
      // 缓存
      try{
        window.OfferWall?.putString('accountInfo', JSON.stringify(data));
      }catch(e){
        console.log('android not set', e);
      }
    },
    action_get_subList({ commit }) {
      return new Promise((resolve) => {
        commit("change_loading", true);
        // 获取子任务列表
        api.offerChildListApi({
          svName: "/offsl",
          mdName: "post",
          offerIdList: []
        }).then((res) => {
          console.log('res123',res);
          commit("change_loading", false);
          if (res.code == 0) {
            commit("update_subList", {data: res.data});
            resolve(res.data);
          } else {
            resolve(undefined);
          }
        });
      });
    },
  },
});

export default store;
